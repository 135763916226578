<template>
  <tr @mouseleave="showFav = false" @mouseover="showFav = true">
    <td class="txt_wrap cursor-pointer ps-3" :title="item.name">
      {{ item.name }}
    </td>
    <td>{{ pad(item.modelId, 4) }}</td>
    <td
      class="txt_wrap cursor-pointer"
      v-if="isPurchase"
      :title="item.HerstellerModellName"
    >
      {{ item.HerstellerModellName }}
    </td>
    <td class="txt_wrap text-color-primary" :title="item.status">
      {{ item.status }}
    </td>
    <td>
      <div class="d-flex justify-content-center">
        {{ item.PreisbindungVon }}
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-center">
        {{ item.PreisbindungBis }}
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-center">
        {{
          item.pricelistUpdatedAt
            ? $moment(item.pricelistUpdatedAt).format('DD.MM.YYYY')
            : ''
        }}
      </div>
    </td>
    <td class="van_wrap">
      <div>
        <CopyButton :text="getVAN(item.supplierId, item.modelId)" />
      </div>
    </td>
    <td class="txt_wrap cursor-pointer">
      <b v-if="showTzText(item)" :title="getTzText(item)">{{
        getTzText(item)
      }}</b>
    </td>
    <td><b v-if="item.isExclusive" class="text-color-primary">Exklusiv</b></td>
    <td class="txt_wrap text-color-primary">
      <b v-if="item.Handelsmarken" :title="item.Handelsmarken">{{
        item.Handelsmarken
      }}</b>
    </td>
    <td v-if="isTrendhopper || isInterliving">
      <PdfDownload
        v-if="UVPsPdfDownloadBtn"
        :item="item.validUVPs[0]"
        :name="item.name"
        :isInterliving="isInterliving"
        :isTrendhopper="isTrendhopper"
      />
      <u
        v-else-if="item.validUVPs.length"
        @click="navToModelDetail"
        class="text-color-primary cursor-pointer"
        >Link</u
      >
    </td>
    <td v-else-if="!isPurchase">
      <PdfDownload
        v-if="verkaufPdfDownloadBtn"
        :item="item.validSellerCatalogues[0]"
        :name="item.name"
      />
      <u
        v-else-if="item.validSellerCatalogues.length"
        @click="navToModelDetail"
        class="text-color-primary cursor-pointer"
        >Link</u
      >
    </td>
    <td
      v-else
      v-for="idx in 2"
      :key="`${idx}`"
      :class="{ 'text-center': idx == 2 }"
      style="width: 115px"
    >
      <PdfDownload
        v-if="showPdfDownloadBtn"
        :item="pdfArr[idx - 1]"
        :name="item.name"
      />
      <u
        v-else-if="hasValidPurchaserCatalogues()"
        @click="navToModelDetail"
        class="text-color-primary cursor-pointer"
        >Link</u
      >
    </td>
    <td class="cursor-pointer" style="width: 80px">
      <star-component
        v-if="showFav || isFav"
        ref="star"
        :isFavorite="isFav"
        @onFav="onFav"
        listingType="table"
        :size="20"
      />
    </td>
    <td
      @click="navToModelDetail"
      style="width: 30px"
      class="text-right cursor-pointer pe-3"
    >
      <ph-caret-right :size="14" color="#787878" />
    </td>
  </tr>
</template>
<script>
import { PhCaretRight } from 'phosphor-vue'
import starComponent from './starComponent.vue'
import PdfDownload from './PdfDownload.vue'
import CopyButton from '../ui/CopyButton.vue'

export default {
  name: 'TableView',
  components: {
    PdfDownload,
    PhCaretRight,
    starComponent,
    CopyButton
  },
  props: {
    item: {
      required: true,
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showFav: false,
      isFav: this.item.isFavorite
    }
  },
  computed: {
    pdfArr() {
      return this.item.validPurchaserCatalogues.slice().reverse()
    },
    showPdfDownloadBtn() {
      return (
        this.item.validPurchaserCatalogues &&
        this.item.validPurchaserCatalogues.length === 2 &&
        this.item.validPurchaserCatalogues[0].stepName !==
          this.item.validPurchaserCatalogues[1].stepName
      )
    },
    verkaufPdfDownloadBtn() {
      return (
        this.item.validSellerCatalogues &&
        this.item.validSellerCatalogues.length === 1
      )
    },
    UVPsPdfDownloadBtn() {
      return this.item.validUVPs && this.item.validUVPs.length === 1
    },
    isPurchase() {
      return this.$route.name === 'buyerModels'
    },
    favType() {
      return this.$store.getters.getFavoriteType
    },
    isTrendhopper() {
      return (
        this.item.Exklusivität === 'Trendhopper' ||
        this.item.Modelmerker === 'TH' ||
        this.item.Handelsmarken === 'TRENDHOPPER' ||
        this.item.Sortiment === 'TH'
      )
    },
    isInterliving() {
      return (
        this.item.supplierId.charAt(0) === '9' &&
        this.supplierId !== '999344' &&
        this.$route.name === 'sellerModels'
      )
    }
  },
  methods: {
    hasTeuerungszuschlagInfo(item) {
      return (
        item.TeuerungszuschlagInProzent ||
        item.TeuerungszuschlagInformation ||
        item.TeuerungszuschlagGültigVon ||
        item.TeuerungszuschlagGültigBis
      )
    },
    showTzText(item) {
      return item.tz || this.hasTeuerungszuschlagInfo(item)
    },
    hasValidPurchaserCatalogues() {
      return (
        this.item.validPurchaserCatalogues &&
        this.item.validPurchaserCatalogues.length
      )
    },
    getTzText(item) {
      if (item.tz === true) {
        return 'PL - inkl. TZ kalkuliert'
      }
      if (item.tz === false && this.hasTeuerungszuschlagInfo(item)) {
        return 'PL, ohne TZ kalkuliert bitte TZ-Informationen beachten'
      }
      if (!item.tz && this.hasTeuerungszuschlagInfo(item)) {
        return 'TZ-Infos im Modell beachten'
      }
      return ''
    },
    navToModelDetail() {
      this.$router.push(
        `${this.$route.path.slice(0, -1)}/${this.item.modelId}?supplierId=${
          this.item.supplierId
        }`
      )
    },
    async onFav(e, post) {
      try {
        let url = ''
        if (e === this.favType) {
          this.isFav = post
          this.showFav = post
          this.$refs.star.icoType = post ? 'fill' : 'thin'
          url = post ? '' : '/delete'
        }
        await this.$api.setFavorite(url, {
          type: 'model',
          access: e,
          modelId: this.item.modelId,
          supplierId: this.item.supplierId
        })
        this.$emit('favChanged')
      } catch (e) {
        console.error(e)
      }
    },
    getVAN(supp, mod) {
      return `${this.pad(supp, 6)}${this.pad(mod, 4)}`
    },
    pad(num, size) {
      num = num.toString()
      while (num.length < size) num = '0' + num
      return num
    }
  }
}
</script>
