<template>
  <div
    @click="clickItem(item)"
    class="groups-item-childs-child model"
    :class="listingType === 'box' ? 'box' : 'list'"
    @mouseleave="showFav = false"
    @mouseover="showFav = true"
  >
    <div
      class="groups-item-childs-child-header d-flex align-items-start"
      :class="{
        'justify-content-between': listingType == 'box',
        'search-model': isSearch
      }"
    >
      <div class="left" :class="listingType === 'list' ? ' order-2' : null">
        <div class="d-flex align-items-center gap-4">
          <CopyButton
            :text="getVAN(item.supplierId, item.modelId)"
            :text-class="'text-color-primary text-xs m-0'"
          />
        </div>
        <p class="text-color-primary text-xs mb-2">
          {{ `(${pad(item.supplierId, 6)}-${pad(item.modelId, 4)})` }}
        </p>
        <h3 class="text-color-gray text-sm">{{ item.supplierName }}</h3>
        <h3 class="text-color-gray text-sm">Modell: {{ item.name }}</h3>
        <h3 class="text-color-gray text-sm" v-if="isPurchase">
          Hersteller Modell: {{ item.HerstellerModellName }}
        </h3>
      </div>
      <div
        class="right model-list-right"
        :class="listingType === 'list' ? ' order-1 me-4' : null"
      >
        <img
          :src="item.logo"
          @error="replaceByDefaultImg"
          alt
          class="w-100"
          height="106"
        />
        <star-component
          v-if="listingType !== 'list' && (showFav || isFav)"
          ref="star"
          :isFavorite="isFav"
          @onFav="onFav"
          :listingType="listingType"
        />
      </div>
    </div>
    <div
      class="text-sm"
      :style="listingType === 'list' ? 'padding-left:2em' : 'margin-top:1em'"
      v-if="showTzText(item)"
    >
      <span>{{ getTzText(item) }}</span>
    </div>
    <div
      class="item d-flex group warengruppe"
      :class="
        listingType === 'box'
          ? `mt-${item.tz == undefined ? 5 : 3}`
          : 'justify-content-center  w-50'
      "
    >
      <div class="d-flex flex-column">
        <span class="text text-xs text-color-65-gray">Warengruppe</span>
        <span class="count text-sm text-color-65-gray font-bold mt-1">{{
          item.goodsGroup ? item.goodsGroup.split(' ')[0] : ''
        }}</span>
        <div
          class="mt-4 text-sm font-bold text-color-primary"
          v-if="item.Handelsmarken"
        >
          {{ item.Handelsmarken }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-column w-100 justify-content-between">
      <div
        class="d-flex model-footer"
        :class="listingType === 'box' ? 'box-footer' : 'list-footer h-100'"
        :style="listingType === 'box' ? 'margin-top: 30px' : ''"
      >
        <div
          class="w-100 d-flex justify-content-between flex-wrap"
          :class="{ 'search-model justify-content-end': isSearch }"
        >
          <div class="item d-flex flex-column mb-2">
            <div class="d-flex flex-column">
              <span class="text text-xs text-color-65-gray">Gültig ab</span>
              <span class="count text-sm text-color-65-gray font-bold">{{
                item.PreisbindungVon
              }}</span>
            </div>
            <div class="d-flex flex-column mt-4">
              <span class="text text-xs text-color-65-gray"
                >Zuletzt bearbeitet</span
              >
              <span class="count text-sm text-color-65-gray font-bold">{{
                item.pricelistUpdatedAt
                  ? $moment(item.pricelistUpdatedAt).format('DD.MM.YYYY')
                  : ''
              }}</span>
            </div>
          </div>
          <div class="item d-flex flex-column mb-2">
            <div class="d-flex flex-column">
              <span class="text text-xs text-color-65-gray">Gültig bis</span>
              <span class="count text-sm text-color-65-gray font-bold">{{
                item.PreisbindungBis
              }}</span>
            </div>
            <div
              class="font-bold text-color-primary mt-4"
              v-if="item.isExclusive"
            >
              Exklusiv
            </div>
          </div>
          <div
            class="d-flex flex-column position-relative"
            style="width: 120px"
          >
            <div class="d-flex flex-column me-2">
              <span class="text text-xs text-color-65-gray" v-if="item.status"
                >Status</span
              >
              <span
                class="count text-sm text-color-65-gray font-bold"
                :class="`text-color-${
                  item.status === 'inaktiv' ? 'danger' : 'success'
                }`"
                >{{ item.status }}</span
              >
            </div>

            <div
              class="d-flex align-items-center w-100"
              :class="
                listingType === 'list'
                  ? 'position-absolute top-50 start-50 translate-middle justify-content-start'
                  : 'justify-content-center'
              "
              style="margin: auto 0"
            >
              <div
                v-if="listingType === 'list' && !(showFav || isFav)"
                class="me-2"
                style="width: 28px; height: 28px"
              />
              <star-component
                v-if="listingType === 'list' && (showFav || isFav)"
                ref="star"
                class="pe-2"
                :isFavorite="isFav"
                @onFav="onFav"
                :listingType="listingType"
              />
              <div
                class="item cursor-pointer d-flex flex-column"
                :class="!isSearch ? 'ms-auto' : null"
              >
                <ph-caret-right weight="bold" :size="18" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhCaretRight } from 'phosphor-vue'
import starComponent from './starComponent.vue'
import CopyButton from '../ui/CopyButton.vue'

export default {
  props: {
    isSearch: {
      type: Boolean,
      default: false
    },
    listingType: {
      required: true,
      type: String
    },
    item: {
      required: true,
      type: Object,
      default: () => {}
    }
  },
  components: {
    PhCaretRight,
    starComponent,
    CopyButton
  },
  data() {
    return {
      showFav: false,
      isFav: this.item.isFavorite,
      isPurchase: this.$route.name === 'buyerModels'
    }
  },
  computed: {
    favType() {
      return this.$store.getters.getFavoriteType
    }
  },
  methods: {
    replaceByDefaultImg(e) {
      e.target.src = '/imgs/missing_photo.svg'
      e.target.width = 106
    },
    clickItem(item) {
      this.$emit('clickItem', item)
    },
    async onFav(e, post) {
      try {
        let url = ''
        if (e === this.favType) {
          this.isFav = post
          this.showFav = post
          this.$refs.star.icoType = post ? 'fill' : 'thin'
          url = post ? '' : '/delete'
        }
        await this.$api.setFavorite(url, {
          type: 'model',
          access: e,
          modelId: this.item.modelId,
          supplierId: this.item.supplierId
        })
      } catch (e) {
        console.error(e)
      }
    },
    hasTeuerungszuschlagInfo(item) {
      return (
        item.TeuerungszuschlagInProzent ||
        item.TeuerungszuschlagInformation ||
        item.TeuerungszuschlagGültigVon ||
        item.TeuerungszuschlagGültigBis
      )
    },
    showTzText(item) {
      return item.tz || this.hasTeuerungszuschlagInfo(item)
    },
    getTzText(item) {
      if (item.tz === true) {
        return 'PL - inkl. TZ kalkuliert'
      }
      if (item.tz === false && this.hasTeuerungszuschlagInfo(item)) {
        return 'PL, ohne TZ kalkuliert bitte TZ-Informationen beachten'
      }
      if (!item.tz && this.hasTeuerungszuschlagInfo(item)) {
        return 'TZ-Infos im Modell beachten'
      }
      return ''
    },
    getVAN(supp, mod) {
      return `${this.pad(supp, 6)}${this.pad(mod, 4)}`
    },
    pad(num, size) {
      num = num.toString()
      while (num.length < size) num = '0' + num
      return num
    }
  }
}
</script>
