<template>
  <div :class="listingType === 'list' ? '' : 'star-ico'" :style="listingType === 'table' ? ' margin-top: 0;width:100%':''">
    <div @click.stop="showfavuo = true">
      <ph-star :size="size" :weight="icoType" color="#ee7f00" @mouseleave="onLeave" @mouseover="icoType='fill'" />
    </div>
    <div v-if="showfavuo" class="fav_opt" @mouseleave="showfavuo = false">
      <p @click.stop="onFav('private')">Persönliche</p>
      <p @click.stop="onFav('public')">Unternehmen</p>
    </div>
  </div>
</template>

<script>
import { PhStar } from 'phosphor-vue'
export default {
  name: 'StarComponent',
  props: {
    listingType: String,
    isFavorite: Boolean,
    size: {
      type: Number,
      default: 28
    }
  },
  components: {
    PhStar
  },
  data () {
    return {
      showfavuo: false,
      icoType: this.isFavorite ? 'fill' : 'thin'
    }
  },
  methods: {
    async onFav (e) {
      this.showfavuo = false
      this.$emit('onFav', e, !this.isFavorite)
    },
    onLeave () {
      if (!this.isFavorite) { this.icoType = 'thin' }
    }
  }
}
</script>

<style scoped lang="scss">
.star-ico {
  width: 0;
  align-self: flex-start;
  margin-top: -22px;
}
// .star-right {
//   position: absolute;
//   right: 165px;
//   margin-top: 30px;
// }
.fav_opt {
  position: absolute;
  margin: -15px 0 0 10px;
  background-color: #f9f9f9;
  border: solid #EE7F00 1px;
  border-radius: 10px;
}
.fav_opt p {
  margin-bottom: 0;
  padding: 4px 15px 4px 15px;
}
.fav_opt p:first-child {
  border-radius: 8px 8px 0 0;
}
.fav_opt p:last-child {
  border-radius: 0 0 8px 8px;
}

.fav_opt p:hover {
  background-color: #EE7F00;
  color: white;
}
</style>
