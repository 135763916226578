<template>
  <div
    class="text-container"
    @click.stop="copyToClipboard"
    @mouseover="showIcon"
    @mouseleave="hideIcon"
  >
    <span class="text" :class="textClass">{{ text }}</span>
    <div class="icon-wrapper">
      <svg width="15" height="15" viewBox="0 0 20 20" v-show="iconVisible">
        <rect
          width="12"
          height="16"
          fill="none"
          stroke="#ee7f00"
          x="3.5"
          y="2.5"
        ></rect>
        <polyline
          fill="none"
          stroke="#ee7f00"
          points="5 0.5 17.5 0.5 17.5 17"
        ></polyline>
      </svg>
    </div>
    <span v-if="tooltipVisible" class="tooltip">{{ tooltipText }}</span>
  </div>
</template>

<script>
export default {
  name: 'textWithIcon',
  props: {
    text: {
      type: String,
      required: true
    },
    textClass: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      tooltipVisible: false,
      tooltipText: 'Kopiere ID in die Zwischenablage',
      iconVisible: false
    }
  },
  methods: {
    copyToClipboard() {
      const el = document.createElement('textarea')
      el.value = this.text
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.tooltipText = 'Kopiert!'
      setTimeout(() => {
        this.tooltipText = 'Kopiere ID in die Zwischenablage'
      }, 500)
    },
    showIcon() {
      this.iconVisible = true
      this.tooltipVisible = true
    },
    hideIcon() {
      this.iconVisible = false
      this.tooltipVisible = false
    }
  }
}
</script>

<style scoped>
.text-container {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.text {
  display: inline-block;
}

.icon-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  width: 15px;
  height: 15px;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.text-container:hover .icon-wrapper {
  opacity: 1;
}

.tooltip {
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 0 5px;
  border-radius: 3px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0.9;
}
</style>
