<template>
    <div class="page-content">
    <sidebar-menu />
    <perfect-scrollbar @ps-scroll-y="handleScroll" class="w-100" ref="ps">
      <div class="container-fluid">
        <div class="col-12" style="height:80px">
          <page-title />
        </div>
        <div class="d-flex justify-content-end w-100 mt-2">
          <span class="text-color-65-gray me-3">Route:</span>
          <div class="page-list-view-change">
            <div @click="changeRouting(true)" class="px-2 d-flex cursor-pointer">
              <div
                class="item me-2"
                :class="{ active: getVerkauf }"
              >
                <v-icon :icon="['fac', 'ein']" />
              </div>
              <span :class="{ 'font-bold text-color-primary': getVerkauf }">VERKAUF</span>
            </div>
            <div  @click="changeRouting(false)" class="px-2 d-flex cursor-pointer">
              <div
                class="item"
                :class="{ active: !getVerkauf }"
              >
                <v-icon :icon="['fac', 'verk']" />
              </div>
              <span class="me-2" :class="{ 'font-bold text-color-primary': !getVerkauf}">EINKAUF</span>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div v-if="loading || filterloading" class="loader">Loading...</div>
                <div class="search-body">
                  <list @click="clickItem" v-if="!loading" :type="searchType" :listingType="'list'" :getList="list" />
                  <div v-if="notMoreData && !loadingMore && list.length !== 0" class="row">
                    <div class="col-12">
                      <h3 class="justify-content-center d-flex w-100 mb-5 text-Keine">Keine weiteren Daten</h3>
                    </div>
                  </div>
                  <div v-if="loadingMore" class="row">
                    <div class="col-12">
                      <loading :size="'sm'" />
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="row mt-5  text-center">
            <div v-if="list.length == 0 && !filterloading" class="col-12 mt-5">
              <ph-info weight="thin" :size="80" />
              <h3 class="mt-2">Keine Treffer für Ihre Suchanfrage</h3>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <sidebar-filter  :path='path'/>
  </div>
</template>
<script>
import List from '@/components/common/List.vue'
import Loading from '@/components/common/Loading.vue'
import SidebarMenu from '../components/common/SidebarMenu.vue'
import SidebarFilter from '../components/common/SidebarFilter.vue'
import PageTitle from '@/components/common/PageTitle.vue'
import { PhInfo } from 'phosphor-vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: { PhInfo, List, Loading, SidebarMenu, SidebarFilter, PageTitle },
  data () {
    return {
      path: 'seller',
      page: 1,
      sort: 'asc',
      list: [],
      paginator: {},
      searchType: 'model',
      loading: false,
      loadingMore: false,
      notMoreData: false,
      filterloading: false
    }
  },
  computed: {
    ...mapGetters(['getQuery', 'getModels', 'getSuppliers', 'getGoodsgroups', 'getPriceList', 'getVerkauf'])
  },
  methods: {
    ...mapMutations(['SET_BREADCRUMB', 'SET_VERKAUF']),
    handleScroll (el) {
      if (
        el.srcElement.offsetHeight + el.srcElement.scrollTop >=
        el.srcElement.scrollHeight
      ) {
        this.moreData()
      }
    },
    moreData () {
      if (this.page === this.paginator.totalPages) {
        this.notMoreData = true
        return
      }
      if (!this.loadingMore) {
        this.page++
        this.search(true)
      }
    },
    changeRouting (par) {
      this.SET_VERKAUF(par)
    },
    clickItem (par) {
      if (par.modelId) {
        if (this.getVerkauf) {
          this.$router.push(`/seller/model/${this.pad(par.modelId, 4)}?supplierId=${par.supplierId}`)
        } else {
          this.$router.push(`/buyer/model/${this.pad(par.modelId, 4)}?supplierId=${par.supplierId}`)
        }
      }
    },
    async search (more = false) {
      try {
        if (more) {
          this.loadingMore = true
        }
        this.filterloading = true
        let items = []
        let response = null
        if (this.$route.query.global) {
          response = await this.$api.searchData({ page: this.page, searchKeyw: this.$route.query.supplier })
        } else {
          let query = { modelStatus: 'all' }
          if (this.getQuery.isExclusive) query = { ...query, isExclusive: this.getQuery.isExclusive }
          if (this.getQuery.hasSPCZFile) query = { ...query, hasSPCZFile: this.getQuery.hasSPCZFile }
          if (this.getQuery.changedSince) query = { ...query, changedSince: this.getQuery.changedSince }
          if (this.getModels.selected?.modelId) query = { ...query, modelId: this.getModels.selected?.modelId }
          query = { ...query, modelStatus: this.getQuery.modelStatus ? 'all' : 'active' }
          if (this.getSuppliers.selected?.supplierId) query = { ...query, supplierId: this.getSuppliers.selected?.supplierId }
          if (this.getGoodsgroups.selected?.goodsGroup) query = { ...query, goodsGroup: this.getGoodsgroups.selected?.goodsGroup }
          if (this.$route.query.pl || this.getPriceList.selected?.length) {
            const plm = []
            this.getPriceList.selected.map(pl => plm.push(pl.name))
            query = { ...query, preislistenmerkers: plm }
          }

          let payload = { page: this.page, sortType: this.sort }
          if (query) {
            payload = { ...payload, filter: JSON.stringify(query) }
          }
          response = await this.$api.filterModel(payload)
        }
        this.paginator = response.data.data.paginator
        items = response.data.data.items
        if (!more) {
          this.list = []
        }
        items.map((item) => {
          const group = item.name.charAt(0)
          const findIndex = this.list.findIndex((item) => item.group === group)

          if (findIndex === -1) {
            this.list.push({
              group,
              items: []
            })
          }
          const findIndexIshave = this.list.findIndex(
            (item) => item.group === group
          )
          if (findIndexIshave !== -1) {
            this.list[findIndexIshave].items.push({ ...item })
          }
        })
        this.filterloading = false
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
        if (more) {
          this.loadingMore = false
        }
      }
    },
    pad (num, size) {
      num = num.toString()
      while (num.length < size) num = '0' + num
      return num
    }
  },
  created () {
    const data = [
      {
        title: this.$route.query.supplier
      },
      {
        title: ' / Suchergebnisse'
      }
    ]
    this.SET_BREADCRUMB(data)
    this.search()
  }
}
</script>
<style scoped>
</style>
