<template>
  <div class="groups">
    <template v-if="tableView">
      <table-list
        @sortT="onSortT"
        :getList="getList"
        :tableView="tableView"
        :sortT="sortT"
      />
    </template>
    <template v-else v-for="(group, index) in getList">
      <div v-if="group.group !== '-'" :key="index" class="groups-item">
        <div class="groups-item-name mb-3" v-if="!fav">
          {{ group.group }}
        </div>
        <div
          class="groups-item-childs"
          :class="
            listingType === 'box' ? 'd-flex flex-wrap' : 'd-flex flex-column'
          "
        >
          <template v-if="type === 'seller' || type === 'supplier-search'">
            <seller-list-item
              :type="type"
              @favChanged="setItems(index, idx)"
              @clickItem="clickItem"
              :listingType="listingType"
              v-for="(item, idx) in group.items"
              :key="`${idx}${item._id}`"
              :item="item"
            />
          </template>
          <template v-else-if="type === 'model'">
            <model-list-item
              @clickItem="clickItem"
              @favChanged="setItems(index, idx)"
              :isSearch="true"
              :listingType="listingType"
              v-for="(item, idx) in group.items"
              :key="`${idx}${item._id}`"
              :item="item"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SellerListItem from '@/components/common/SellerListItem.vue'
import ModelListItem from '@/components/common/ModelListItem.vue'
import TableList from './TableList.vue'
export default {
  components: { SellerListItem, ModelListItem, TableList },
  props: {
    type: {
      required: true,
      type: String
    },
    listingType: {
      required: true,
      type: String
    },
    getList: {
      required: true,
      type: Array,
      default: () => []
    },
    tableView: {
      type: Number,
      default: 0
    },
    sortT: {
      type: Object,
      default: null
    }
  },
  computed: {
    fav() {
      return this.$store.getters.getFavorite[
        this.$route.name === 'buyerModels' ? 'buyer' : 'seller'
      ][this.type === 'model' ? 'model' : 'supplier']
    }
  },
  methods: {
    clickItem(par) {
      this.$emit('click', par)
    },
    setItems(i, ix) {
      this.$emit('setItms', i, ix)
    },
    onSortT(col, date = false) {
      this.$emit('sortT', col, date)
    }
  }
}
</script>
