<template>
  <span style="min-width: 20px">
    <strong v-if="name">
      <ph-arrow-down v-if="name === 'up'" :size="18"/>
      <ph-arrow-up v-else :size="18"/>
    </strong>
  </span>
</template>
<script>
import { PhArrowDown, PhArrowUp } from 'phosphor-vue'
export default {
  components: { PhArrowDown, PhArrowUp },
  props: {
    name: {
      type: String,
      default: null
    }
  }
}
</script>
