<template>
  <div
    @click="clickItem" class="groups-item-childs-child"
    :class="listingType === 'box' ? 'box' : 'list'"
    @mouseleave="showFav = false" @mouseover="showFav = true"
  >
    <div
      class="groups-item-childs-child-header d-flex align-items-start"
      :class="{'justify-content-between': listingType === 'box', 'search': type === 'supplier-search'}"
    >
      <div class="left" :class="listingType === 'list' ? 'me-2' : 'me-0'">
        <h3 class="text-color-primary text-xs">{{ item.supplierId }}</h3>
        <h3 class="text-color-gray text-md text-uppercase">{{ item.fullName }}</h3>
      </div>
      <div class="me-2">
      <star-component v-if="listingType === 'box' && (showFav || isFav)" ref="star" :isFavorite="isFav" @onFav="onFav" :listingType="listingType" />
      </div>
    </div>
    <div class="box-footer d-flex" :class="listingType === 'box' ? 'box-footer' : 'list-footer'">
      <div class="left w-100 d-flex">
        <div class="item d-flex flex-column me-5">
          <span class="count text-sm text-color-65-gray font-bold">{{ item.totalActiveModel }}</span>
          <span class="text text-xs text-color-65-gray">Modelle</span>
        </div>
        <div class="item d-flex flex-column">
          <span class="count text-sm text-color-65-gray font-bold">{{ item.totalValidCatalogue }}</span>
          <span class="text text-xs text-color-65-gray">Kataloge</span>
        </div>
        <star-component v-if="listingType === 'list' && (showFav || isFav)" ref="star" :isFavorite="isFav" @onFav="onFav" :listingType="listingType" class="ms-auto pe-2"/>
        <div :class="(listingType !== 'list' || !(showFav || isFav)) ? 'ms-auto' : ''"  class="item cursor-pointer d-flex flex-row ">
          <ph-caret-right weight="bold" :size="18" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import starComponent from './starComponent.vue'
import { PhCaretRight } from 'phosphor-vue'
export default {
  props: {
    listingType: {
      required: true,
      type: String
    },
    type: {
      type: String
    },
    item: {
      required: true,
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      showFav: false,
      isFav: this.item.isFavorite
    }
  },
  components: {
    PhCaretRight,
    starComponent
  },
  computed: {
    favType () {
      return this.$store.getters.getFavoriteType
    }
  },
  methods: {
    clickItem () {
      this.$emit('clickItem', this.item)
    },
    async onFav (e, post) {
      try {
        let url = ''
        if (e === this.favType) {
          this.isFav = post
          this.showFav = post
          this.$refs.star.icoType = post ? 'fill' : 'thin'
          url = post ? '' : '/delete'
        }
        await this.$api.setFavorite(url, { type: 'supplier', access: e, supplierId: this.item.supplierId })
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>
